import React from 'react';
import '../styles/contacto.css';
import mapa from '../img/mapa.svg';
import celular from '../img/phone.svg';


function Contacto() {
    return (<div>
        <div className='contacto-principal'>
            <div className='div-imagen'>
                <img className='imagen' src={celular} alt='imagen de contacto' />
            </div>
            <div className='contacto-horizontal'>
                <p className='texto-principal'>Llámanos</p>
                <a className='texto-secundario' target='_blank' rel='noopener noreferrer' href='tel:+51934653328'>+51 934 653 328</a>
            </div>
        </div>
        <div className='contacto-principal'>
            <div className='div-imagen'>
                <img className='imagen' src={mapa} alt='imagen de contacto' />
            </div>
            <div className='contacto-horizontal'>
                <p className='texto-principal'>Dirección</p>
                <a className='texto-secundario' target='_blank' rel='noopener noreferrer' href='https://www.google.com/maps/place/Nido+Brainy+Fun+La+Molina/@-12.0780382,-76.919706,15z/data=!4m14!1m7!3m6!1s0x9105c6d51ecce1f9:0xb7301c09e2d942a2!2sNido+Brainy+Fun+La+Molina!8m2!3d-12.0780382!4d-76.919706!16s%2Fg%2F11c21hj19y!3m5!1s0x9105c6d51ecce1f9:0xb7301c09e2d942a2!8m2!3d-12.0780382!4d-76.919706!16s%2Fg%2F11c21hj19y'>Calle islas aleutianas 120. La Molina</a>
            </div>
        </div>
    </div>
    )
}

export default Contacto;