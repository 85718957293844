import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/responsive.css';
import '../styles/pagina-servicios.css';
import servicios from '../redux/serviciosDatos';

const PaginaServicios = () => {
    const { id } = useParams();

    const selectedService = servicios.find((servicio) => servicio.id === parseInt(id))

    if (!selectedService) {
        return (<div>No se ha encontrado el serivicio</div>)
    }
    return (
        <div className="service-container">
            <h1 className='titulo-servicio'>{selectedService.titulo}</h1>

            <div className='contenedor-servicio'>
                <div className='div-superior'>
                    <div className='texto-superior'>
                        <p className='subtitulo'>Descripción</p>
                        <p>{selectedService.descripcion}</p>
                    </div>
                    <img src={require(`../img/${selectedService.imagen}`)} alt="Servicio" />
                </div>
                <div className='div-inferior'>
                    <p className='subtitulo'>Caracteristicas</p>
                    <div className='contenedor-cards-servicios'>
                        {selectedService.caracteristicas.map((caracteristica) => (
                            <div  key={selectedService.caracteristicas.indexOf(caracteristica)} caracteristica={caracteristica} className='card-servicio-pagina'>
                                <h3 className='titulo-card'>{caracteristica.titulo}</h3>
                                <p className='texto-card'>{caracteristica.texto}</p>
                            </div>
                        ))}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default PaginaServicios;