export const seleccionarDocente = (docente) => {
    return {
      type: 'SELECCIONAR_DOCENTE',
      payload: docente,
    };
  };

  export const seleccionarServicio = (servicio) => {
    return {
      type: 'SELECCIONAR_SERVICIO',
      payload: servicio,
    };
  };