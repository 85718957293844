const estadoInicial = {
    docenteSeleccionado: null,
  };
  
  const docenteReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
      case 'SELECCIONAR_DOCENTE':
        return {
          ...estado,
          docenteSeleccionado: accion.payload,
        };
      default:
        return estado;
    }
  };
  
  export default docenteReducer;