import React from 'react';
import Servicio from './Servicio';
import servicios from '../redux/serviciosDatos'
import '../styles/servicio.css';
import '../styles/responsive.css';

function Servicios(){
    return (
        <div className='contenedor-servicios'>
          {servicios.map((servicio) => (
            <Servicio key={servicio.id} servicio={servicio} />
          ))}
    </div>

    )
}

export default Servicios;


