import React from "react";
import brainy from '../img/brainyFun.png';
import '../styles/formulario.css';
import BotonPrincipal from '../components/BotonPrincipal';



function Formulario() {
    return (
        <div className='formulario'>
            <img className='imagen-brainy' src={brainy} alt="logo"/>
                <h4>Lorem Ipsum es simplemente el texto de relleno</h4>
                <BotonPrincipal enlace='https://mkd.brainyfun.pe/formulario-de-matriculas-brainy-fun'>¡Contactanos!</BotonPrincipal>
        </div>
    )
}

export default Formulario;