    const docentes = [
        {
          id: 1,
          imagen: "profesora.jpg",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Valeria Moncada",
          cargo: "Profesora de Inglés",
          experiencia: "3 años",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
        {
          id: 2,
          imagen: "1.jpg",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Laura Zuluaga",
          cargo: "Profesora de Inglés",
          experiencia: "5 años",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros","caminar","correr", "viajar","reir"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
        {
          id: 3,
          imagen: "2.jpg",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Elizabeth Quintero",
          cargo: "Profesora de Inglés",
          experiencia: "2 años",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
        {
          id: 4,
          imagen: "3.jpg",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Maria Moreno",
          cargo: "Profesora de Inglés",
          experiencia: "1 año",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
        {
          id: 5,
          imagen: "4.jpg",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Camila Mendoza",
          cargo: "Profesora de Inglés",
          experiencia: "6 años",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
        {
          id: 6,
          imagen: "Fondo1.png",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Isabela Quiroga",
          cargo: "Profesora de Inglés",
          experiencia: "9 años",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
        {
          id: 7,
          imagen: "Fondo1.png",
          linkedin: "https://www.instagram.com/mindcurv/?hl=es",
          instagram: "https://www.linkedin.com/in/midudev/",
          nombre: "Isabela Quiroga",
          cargo: "Profesora de Inglés",
          experiencia: "9 años",
          sobreMi: "Profesora de inglés apasionada por los idiomas y la tecnología, con un gran amor por los niños",
          estudios: ["Licenciatura en Idiomas","Especializacion en pedagogía"],
          hobbies: ["Leer","Ver Series","Viajar","Pasear mis perros"],
          expBrainy: "Rodeada de excelentes compañeros, profesionales y empáticos, haciendo de Brainy Fun una segunda Familia",
        },
      ];
      
      export default docentes;