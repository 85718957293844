import React from 'react';
import '../styles/boton-principal.css';

function BotonPrincipal(props) {
    return (
        <div className='div-btn-matricula'>
            <a target='_blank' 
                href={props.enlace} 
                className='btn-principal'
                rel="noopener noreferrer"    
            >{props.children}</a>
        </div>
    )
}

export default BotonPrincipal;