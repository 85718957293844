import React from "react";
import "../styles/why-notas-politicas.css";
import { Row, Col, Table } from "react-bootstrap";

const PrivacyPolicy = () => {

    return (
        <div className="contenedor-politicas">
            <Row>
                <Col>
                    <h2>Política de privacidad</h2>
                    <p>Para Brainy Fun Preschool Inc. Tus datos son muy importantes, y por ello estamos comprometidos con su privacidad y protección.</p>
                    <p>La información/datos que recopilamos de ti nos permite: </p> 
                    <ul>
                        <li>identificarte,</li> 
                        <li>localizarte,</li>  
                        <li>comunicarte,</li> 
                        <li>contactarte,</li>
                        <li>enviarte información,</li>
                        <li>y, o dar cumplimiento a obligaciones que hemos contraído contigo.</li>
                    </ul>
                    <p>De ninguna manera venderemos o comercializaremos tus datos, nuestra política de privacidad se basa en la política de privacidad
                        de nuestra casa matríz <a href="https://www.gvalor.co" target="blank">Grupo Educativo Valor SAC</a>, de hecho las lineas anteriores que acabas de leer son un abstracto de dicha política.</p>
                    <p>Si deseas consultar todos los detalles adicionales de nuestra política de privacidad, puedes hacerlo <a href="https://www.gvalor.co/politica-de-privacidad">aquí</a>.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Condiciones para la prestación de nuestros servicios educativos</h2>
                    <ul>
                        <li>
                            Brainy Fun Preschool Inc es un centro de estimulación y desarrollo de las habilidades integrales de los niños y niñas, 
                            prestas sus servicios educativos para el nivel de inicial con un enfoque de aprendizaje vivencial, 
                            bilingüe(inglés) y altamente personalizado. Gestionamos el servicio educativo, con el objetivo de lograr 
                            aprendizaje y formación integral de la más alta calidad para los alumn@s, propiciando la convivencia sana, 
                            inclusiva y acogedora en el marco de su autonomía pedagógica e institucional.
                        </li>
                        <li>
                            Los niveles y horarios escolares son: <br></br>
                            <Table striped bordered hover>
                                <thead className='text-center'> 
                                    <tr >
                                        <th>Edad</th>
                                        <th>Aula</th>
                                        <th>Horario</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    <tr className='text-center'>
                                        <td>1 año</td>
                                        <td >Imagine</td>
                                        <td >9:00 a.m.-12:00 m.</td>
                                    </tr>
                                    <tr>
                                        <td>2 años</td>
                                        <td >Create</td>
                                        <td >8:45 a.m.-12:30 p.m.</td>
                                    </tr>
                                    <tr>
                                        <td>3 años</td>
                                        <td>Learn</td>
                                        <td >8:30 a.m.- 12:45 p.m.</td>
                                    </tr>
                                    <tr>
                                        <td>4 años</td>
                                        <td>Growth</td>
                                        <td >8:15 a.m.-1:00 p.m.</td>
                                    </tr>
                                    <tr>
                                        <td>5 años</td>
                                        <td>Kinder</td>
                                        <td >8:00 a.m.-1:15 p.m.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </li>
                        <li>
                            Brainy Fun Preschool Inc. puede ofrecer actividades y cursos extracurriculares durante el año escolar y/o durante el verano, 
                            cuya matrícula es opcional e implica el pago de una contraprestación económica.
                        </li>
                        <li>
                            Las obligaciones económicas que los padres de familia o apoderados asumen al matricular a sus hijos(as) en Brainy Fun Preschool Inc. son: <br></br>
                                <ul>
                                    <li>Derecho de matrícula y</li>  
                                    <li>Pensión de estudio.</li>  
                                </ul>
                        </li>
                        <li>
                            La matrícula es el pago que se realiza una vez por cada año académico, y previa suscripción de los documentos de matrícula que 
                            establecen las condiciones del servicio educativo y los compromisos de los padres de familia.
                        </li>
                        <li>
                            El año académico se compone de diez (10) meses, de marzo a diciembre, de acuerdo al calendario escolar que se publica al 
                            inicio del año; y se cancela una pensión por cada mes lectivo del año escolar (los 10 primeros días de cada mes). 
                        </li>
                        <li>
                            Al adquirir el formulario de inscripción, y realizar el pago de matrícula del año escolar, los padres de familia se 
                            comprometen a realizar los pagos de manera oportuna durante los 10 meses del año académico escolar.
                        </li>
                        <li>
                            De presentarse el caso de incumplimiento con el pago de las obligaciones financieras adquiridas, Brainy Fun Preschool Inc.
                            procederá a realizar de manera automática el cobro de los intereses moratorios correspondientes a partir del onceavo día útil del mes. 
                            Esta disposición se encuentra alineada con <a href='https://img.lpderecho.pe/wp-content/uploads/2020/03/C%C3%B3digo-civil-03.2020-LP.pdf' rel="noopener noreferrer" target='_blank' >el artículo 1242° del Código Civil</a>, y 
                            el procedimiento establecido para el cobro de deudas pendientes con la institución. 
                        </li>
                        <li>
                            Brainy Fun Preschool Inc. incluye en el valor de la pensión, los materiales educativos para cada nivel escolar, 
                            por lo que no se solicitan valores adicionales.
                        </li>
                        <li>
                            El centro de estimulación y desarrollo cuenta con un uniforme de verano y un uniforme de invierno, los cuales serán asumidos 
                            económicamente por los padres de familia. 
                        </li>
                        <li>
                            Cualquier cobro distinto a los anteriormente mencionados, serán evaluados y aprobados previamente por los padres de familia. 
                        </li>
                        <li>
                            Brainy Fun Preschool Inc. no realiza devoluciones de dinero bajo ningún concepto. Las inasistencias de los alumnos no son causales de 
                            devolución o descuento en los valores de las contraprestaciones económicas adquiridas por los padres de familia o tutores 
                            legales con la institución.
                        </li>
                        <li>
                            La comunicación será continua y permanente, a través de nuestra plataforma “Rocket” y el whatsapp institucional. Brainy Fun Preschool Inc.
                            se reserva el derecho de aperturar otros canales de comunicación si lo considera oportuno y necesario.
                        </li>
                        <li>
                            La información sobre los resultados y orientaciones del proceso educativo y formativo de su hijo(a), serán entregados a los 
                            padres de familia/tutor legal, a través de cuatro informes bimestrales, con una retroalimentación y apoyo que se brinda 
                            de manera individual para cada familia. 
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
};

export default PrivacyPolicy;