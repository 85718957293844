import React from "react";
import "../styles/why-notas-politicas.css";
import "../styles/responsive.css";
import { Card } from "react-bootstrap";
import img1 from "../img/1.jpg";
import img2 from "../img/2.jpg";
import img3 from "../img/3.jpg";
import img4 from "../img/4.jpg";

const WhyUs = () => {
  return (
    <div className="contenedor-why">

          <Card className="card-why">
            <Card.Header className="text-center">Sobre nosotros</Card.Header>
            <Card.Img variant="top" src={img1} />
            <Card.Body>
              <Card.Text>
                Brainy Fun Preschool es un centro de desarrollo infantil, donde
                nos enfocamos en fomentar las habilidades sociales,
                comunicativas y cognitivas de nuestros pequeños y nuestras
                pequeñas, estimulándolos de manera integral. Nuestro objetivo es
                apoyar a los niños y niñas en su crecimiento y desarrollo, para
                que puedan alcanzar su máximo potencial en todas las áreas de su
                vida.
              </Card.Text>
            </Card.Body>
            <Card.Header className="text-center">
              <Card.Link href="https://rocket.gvalor.co/matriculas?instId=3685be60-7c98-11eb-bf45-79dd1dfe71cc&fId=a59f9c00-a269-11ed-9f6a-bf673ded8bf1">
                ¡Matriculas abiertas!
              </Card.Link>
            </Card.Header>
          </Card>

          <Card  className="card-why">
            <Card.Header className="text-center">
              Educación bilingüe
            </Card.Header>
            <Card.Body>
              <Card.Text>
                En Brainy Fun, tus hijos e hijas se encontrarán inmersos en un
                ambiente divertido, motivador y acogedor, que les ayudará a
                fortalecer y desarrollar sus habilidades artísticas, sociales y
                educativas. Nuestro enfoque en el aprendizaje integral les
                permitirá crecer de manera holística, apoyados en programas y
                actividades bilingües (inglés - español) diseñadas
                cuidadosamente para desarrollar su curiosidad y creatividad.
              </Card.Text>
            </Card.Body>
            <Card.Img variant="top" src={img2} />
            <Card.Header className="text-center">
              <Card.Link href="https://rocket.gvalor.co/matriculas?instId=3685be60-7c98-11eb-bf45-79dd1dfe71cc&fId=a59f9c00-a269-11ed-9f6a-bf673ded8bf1">
                ¡Matriculas abiertas!
              </Card.Link>
            </Card.Header>
          </Card>

          <Card  className="card-why">
            <Card.Header className="text-center">
              Educación personalizada
            </Card.Header>
            <Card.Img variant="top" src={img4} />
            <Card.Body>
              <Card.Text>
                En nuestro centro, trabajamos con amor y profesionalismo,
                valores que nos han caracterizado durante todos estos años.
                Nuestras colaboradoras están dedicadas a su labor docente y aman
                lo que hacen, y juntos acompañamos a las familias en el
                desarrollo y aprendizaje de sus hijos e hijas, a través de
                nuestra metodología personalizada CEL (Cognitive Emotional
                Learning) complementada por una experiencia educativa bilingüe
                (Español-Inglés) 100% inmersiva. De esta manera, ofrecemos a
                todos nuestros estudiantes una educación de calidad y adecuada
                para cada una de sus etapas educativas, desde inicial hasta
                secundaria. Al finalizar, nuestros alumnos y alumnas estarán
                preparados para continuar con una educación superior de alto
                nivel.
              </Card.Text>
            </Card.Body>
            <Card.Header className="text-center">
              <Card.Link href="https://rocket.gvalor.co/matriculas?instId=3685be60-7c98-11eb-bf45-79dd1dfe71cc&fId=a59f9c00-a269-11ed-9f6a-bf673ded8bf1">
                ¡Matriculas abiertas!
              </Card.Link>
            </Card.Header>
          </Card>

        <Card  className="card-why">
          <Card.Header className="text-center">
            ¡Se parte de este maravilloso camino!
          </Card.Header>
          <Card.Body>
            <Card.Text>
              ¡Te invitamos a unirte a la hermosa familia Brainy Fun - Skinner!
              donde juntos, casa-colegio, acompañaremos el proceso, crecimiento
              y desarrollo de nuestros pequeños y pequeñas. <br></br>
              <br></br>
              ¡Únete a nuestra comunidad educativa y descubre cómo podemos
              ayudar a tus hijos e hijas a alcanzar todo su potencial!
            </Card.Text>
          </Card.Body>
          <Card.Img variant="top" src={img3} />
          <Card.Header className="text-center">
            <Card.Link href="https://rocket.gvalor.co/matriculas?instId=3685be60-7c98-11eb-bf45-79dd1dfe71cc&fId=a59f9c00-a269-11ed-9f6a-bf673ded8bf1">
              ¡Matriculas abiertas!
            </Card.Link>
          </Card.Header>
        </Card>
    </div>
  );
};

export default WhyUs;
