import React from 'react';
import logoHorizontal from '../img/logohorizontal.png';
import '../styles/header2.css';
import '../styles/responsive.css';
import Contacto from './contacto';
import Link from './links';
import Redes from './redes';

function Header() {
    return (
        <div>
            <div className='header-superior' >
                <img src={logoHorizontal} alt='logo Brainy Fun' className='logo-horizontal' />
                <div className='contacto-header' id='contacto-header'>
                    <Contacto />
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Redes imagen='' />
                    <div className="collapse navbar-collapse contenedor-redes-menu" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link enlace='/' nombre='HOME' />
                            <Link enlace='/#sobre-nosotros' nombre='SOBRE NOSOTROS' />
                            <Link enlace='/#colaboradores' nombre='COLABORADORES' />
                            <Link enlace='/#servicios' nombre='SERVICIOS' />
                            <Link ventana='_blank' enlace='https://mkd.brainyfun.pe/formulario-de-matriculas-brainy-fun' nombre='CONTÁCTANOS' />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}
export default Header;