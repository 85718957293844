import React from 'react';
import '../styles/responsive.css';
import '../styles/pagina-docentes.css';
import { useParams } from 'react-router-dom';
import docentes from '../redux/docentesDatos';
import RedesColaboradores from '../components/redesColaboradores';

const PaginaDocentes = () => {
    const { id } = useParams();

    const docenteSeleccionado = docentes.find((docente) => docente.id === parseInt(id))

    if (!docenteSeleccionado) {
        return (<div>No se ha encontrado el docente</div>)
    }

    return (
        <div className="docente">
            <div className="div-docente-img">
                <img className='imagen-docente' src={require(`../img/${docenteSeleccionado.imagen}`)} alt={docenteSeleccionado.nombre} />
                <RedesColaboradores enlaceLinkedin={docenteSeleccionado.linkedin} enlaceInstagram={docenteSeleccionado.instagram} />
            </div>
            <div className="docente-info">
                <h2 className="nombre-docente">{docenteSeleccionado.nombre}</h2>

                <p>{docenteSeleccionado.cargo}</p>
                <p className="docente-experiencia">Experiencia: {docenteSeleccionado.experiencia}</p>
                <h3 className="subtitulo">Sobre mí</h3>
                <p>{docenteSeleccionado.sobreMi}</p>
                <h3 className="subtitulo">Mis estudios</h3>
               <ul>
                    {docenteSeleccionado.estudios.map((estudio) => (
                        <li key={docenteSeleccionado.estudios.indexOf(estudio)} estudio={estudio} >{estudio}</li>
                    ))}
                </ul>
                <h3 className="subtitulo">Hobbies</h3>
                <div className="hobbies">
                    {docenteSeleccionado.hobbies.map((hobbie) => (
                        <span key={docenteSeleccionado.hobbies.indexOf(hobbie)} hobbie={hobbie}>{hobbie}</span>
                    ))}
                </div>
                <h3 className="subtitulo">Mi experiencia en Brainy Fun</h3>
                <p>{docenteSeleccionado.expBrainy}</p>
            </div>
        </div>
    )
}


export default PaginaDocentes;