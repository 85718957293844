import React from 'react';
import { useDispatch } from 'react-redux';
import {seleccionarDocente} from '../redux/actions';

import '../styles/colaborador.css';
import '../styles/responsive.css';
import cohete from '../img/solologobf.png';

const Colaborador = ({docente}) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(seleccionarDocente(docente))

        window.open(`/docente/${docente.id}` , '_blank');
    };

    return (
        <div className='card'>
            <img src={require(`../img/${docente.imagen}`)} className="imagen-superior" alt={`imagen de ${docente.nombre}`} />
            <div className="card-body">
                <h5 className="card-titulo">{docente.nombre}</h5>
                <p className="card-texto">{docente.sobreMi}</p>
                <div className='contenedor-cohete'>
                    <button onClick={handleClick} className='boton-card'><img className='cohete' src={cohete} alt='boton cohete a pádina del docente' /></button>
                </div>
            </div>
        </div>
    )
}

export default Colaborador;
