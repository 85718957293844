const diferenciadores = [
  {
    id: 1,
    titulo: "Lorem Ipsum 1",
    lugar: "izq",
    imagen: "1.jpg",
    descripcion:
      "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  },
  {
    id: 2,
    titulo: "Lorem Ipsum 2",
    lugar: "izq",
    imagen: "1.jpg",
    descripcion:
      "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  },
  {
    id: 3,
    titulo: "Lorem Ipsum 3",
    lugar: "der",
    imagen: "1.jpg",
    descripcion:
      "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  },
  {
    id: 4,
    titulo: "Lorem Ipsum 4",
    lugar: "der",
    imagen: "1.jpg",
    descripcion:
      "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  },
];

export default diferenciadores;
