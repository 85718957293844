const estadoInicial = {
    servicioSeleccionado: null,
  };
  
  const servicioReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
      case 'SELECCIONAR_SERVICIO':
        return {
          ...estado,
          servicioSeleccionado: accion.payload,
        };
      default:
        return estado;
    }
  };
  
  export default servicioReducer;