import React from 'react';
import { useDispatch } from 'react-redux';
import {seleccionarServicio} from '../redux/actions';

import '../styles/servicio.css'
import '../styles/responsive.css';

const Servicio = ({servicio}) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(seleccionarServicio(servicio))

        window.open(`/servicio/${servicio.id}`, '_blank');
    };
    return (
                <div className={`card-servicio ${servicio.color}`}>
                    <img className='imagen-servicio' src={require(`../img/${servicio.imagen}`)} alt={`imagen servicio ${servicio.titulo}`} />
                    <div className='textos-servicio'>
                        <button className='boton-servicio' onClick={handleClick}>{servicio.titulo}</button>
                        <p>{servicio.descripcionBreve}</p>
                    </div>
                </div>
    )
}

export default Servicio;