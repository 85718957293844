import React from "react";
import Routes from './Routes';
import Header from './components/header2'
import Footer from './components/footer2'

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./redux/reducers";


const estado = createStore(rootReducer);

const App = () => {
  return (
    <Provider store={estado}>
      <Header />
      <Routes />
      <Footer />
    </Provider>
  );
};

export default App;
