import React from 'react';
import Colaborador from './colaborador';
import docentes from '../redux/docentesDatos'
import '../styles/colaborador.css';
import '../styles/responsive.css';

function Colaboradores(){
    return (
        <div className='colaboradores-div'>
          {docentes.map((docente) => (
            <Colaborador key={docente.id} docente={docente} />
          ))}
    </div>

    )
}

export default Colaboradores;