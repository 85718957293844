import React from 'react';
import '../styles/redes.css';
import '../styles/links-redes.css';
import '../styles/responsive.css';

function Redes(props){
    return(
    <div className='redes'>
        <a className={`links ${props.clase}`} target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/brainyfun.pe"><img className='imagen-boton' src={require(`../img/facebook${props.imagen}.svg`)} alt='Facebook Brainy fun' /></a>
        <a className={`links ${props.clase}`} target="_blank" rel="noopener noreferrer"  href="https://wa.me/+51934653328"><img className='imagen-boton' src={require(`../img/whatsapp${props.imagen}.svg`)} alt='WhatsApp Brainy fun' /></a>
        <a className={`links ${props.clase}`} target="_blank" rel="noopener noreferrer"  href="https://www.instagram.com/brainyfunpreschool/"><img className='imagen-boton' src={require(`../img/instagram${props.imagen}.svg`)} alt='Instagram Brainy fun' /></a>
    </div>)
}

export default Redes;
