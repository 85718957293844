import React from 'react';
import '../styles/nuevo-main.css';
import '../styles/responsive.css';

import Carrousel from '../components/carrousel';
import Colaboradores from '../components/colaboradores';
import Diferenciadores from '../components/diferenciadores';
import Servicios from '../components/servicios';
import BotonPrincipal from '../components/BotonPrincipal';
import Formulario from '../components/Formulario';

let NuevoMain = () => {


    return (
        <div>
            <Carrousel id='home'></Carrousel>
            <BotonPrincipal enlace='https://rocket.gvalor.co/matriculas?instId=3685be60-7c98-11eb-bf45-79dd1dfe71cc&fId=a59f9c00-a269-11ed-9f6a-bf673ded8bf1'>MATRICULATE!</BotonPrincipal>
            <h1 className='titulos' id='sobre-nosotros'>
                Bienvenidos a Brainy Fun
            </h1>
            <Diferenciadores />
            <h1 className='titulos right' id='colaboradores'>
                Nuestros colaboradores Brainy Fun
            </h1>
            <div className='div-principal-colaboradores'>
                <Colaboradores />
                <p className='texto-colaboradores'>
                    Lorem Ipsum es simplemente el texto de relleno de las imprentas y
                    archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de
                    las industrias desde el año 1500, cuando un impresor (N. del T.
                    persona que se dedica a la imprenta) desconocido usó una galería de
                    textos y los mezcló de tal manera que logró hacer un libro de textos
                    especimen.Lorem Ipsum es simplemente el texto de relleno de las
                    imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno
                    estándar de las industrias desde el año 1500, cuando un impresor (N.
                    del T. persona que se dedica a la imprenta) desconocido usó una
                    galería de textos y los mezcló de tal manera que logró hacer un libro
                    de textos especimen.
                </p>
            </div>
            <h1 className='titulos' id='servicios'>
                Servicios Brainy Fun
            </h1>
            <Servicios />
            <h1 className='titulos right' id='contactanos'>
                Contáctanos
            </h1>
            <Formulario />
            <h1 className='titulos' id='donde-estamos'>
                Donde nos puedes encontrar
            </h1>
        </div>
    )
}

export default NuevoMain;