import React from 'react';
import '../styles/footer2.css';
import '../styles/responsive.css';
import logo from '../img/logofooter.png'
import Redes from './redes';
import Contacto from './contacto';

function Footer() {
    return (
        <div>
            <div className='footer-superior'>
                <img className='logo-footer' src={logo} alt='logo brainy fun'/>
                <iframe title='mapa a brainy fun' className='mapa-footer' src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15605.991034592309!2d-76.919706!3d-12.0780382!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c6d51ecce1f9%3A0xb7301c09e2d942a2!2sNido%20Brainy%20Fun%20La%20Molina!5e0!3m2!1ses-419!2sco!4v1688668180175!5m2!1ses-419!2sco" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'></iframe>
                <div className='contacto-footer'>
                    <Contacto />
                </div>
            </div>
            <div className='footer-inferior'>
                <p className='texto-pie'>© Grupo Educativo Valor - Todos los derechos reservados.</p>
                <Redes clase='links-blancos' imagen='3'/>
            </div>
        </div>
    )
}
export default Footer;