import React from 'react';
import '../styles/diferenciadores.css'
import '../styles/responsive.css';
import diferenciadores from '../redux/diferenciadoresDatos'


function Diferenciadores() {
    return (
        <div className='contenedor-diferenciadores'>
            {diferenciadores.map((diferenciador) => (
                <div key={diferenciadores.indexOf(diferenciador)} diferenciador={diferenciador} className='content'>
                    <div className='text'>
                        <h4 className='titulo-diferenciador'>{diferenciador.titulo}</h4>
                        <p>{diferenciador.descripcion}</p>
                    </div>
                    <img src={require(`../img/${diferenciador.imagen}`)} className={`imagen-diferenciadora ${diferenciador.lugar}`} alt='imagen diferenciadora' />
                </div>
            ))}
        </div>
    )
}

export default Diferenciadores;
