const servicios = [
    {
        id: 1,
        titulo: "Bilinguismo",
        imagen: "1.jpg",
        color: "fucsia", 
        enlace: "/landing-servicios",
        descripcion: " Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen.",
        caracteristicas: [{id:1 ,titulo: 'Caracteristica 1', texto: 'explicacion de la caracteristica'},{ id: 2, titulo: 'Caracteristica 2', texto: 'explicacion de la caracteristica'},{ id: 3, titulo: 'Caracteristica 3', texto: 'explicacion de la caracteristica'},{id: 4, titulo: 'Caracteristica 4', texto: 'explicacion de la caracteristica'}],
        descripcionBreve: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. ",
    },
    {
        id: 2,
        titulo: "Estimulacion temprana",
        imagen: "2.jpg",
        color: "verde", 
        enlace: "/landing-servicios",
        descripcion: " Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen.",
        caracteristicas: [{id:1 ,titulo: 'Caracteristica 1', texto: 'explicacion de la caracteristica'},{ id: 2, titulo: 'Caracteristica 2', texto: 'explicacion de la caracteristica'},{ id: 3, titulo: 'Caracteristica 3', texto: 'explicacion de la caracteristica'},{id: 4, titulo: 'Caracteristica 4', texto: 'explicacion de la caracteristica'}],
        descripcionBreve: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. ",

    },
    {
        id: 3,
        titulo: "Pre-School",
        imagen: "3.jpg",
        color: "azul", 
        enlace: "/landing-servicios",
        descripcion: " Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen.",
        caracteristicas: [{id:1 ,titulo: 'Caracteristica 1', texto: 'explicacion de la caracteristica'},{ id: 2, titulo: 'Caracteristica 2', texto: 'explicacion de la caracteristica'},{ id: 3, titulo: 'Caracteristica 3', texto: 'explicacion de la caracteristica'},{id: 4, titulo: 'Caracteristica 4', texto: 'explicacion de la caracteristica'}],
        descripcionBreve: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. ",

    },
    {
        id: 4,
        titulo: "After School",
        imagen: "4.jpg",
        color: "morada", 
        enlace: "/landing-servicios",
        descripcion: " Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen.",
        caracteristicas: [{id:1 ,titulo: 'Caracteristica 1', texto: 'explicacion de la caracteristica'},{ id: 2, titulo: 'Caracteristica 2', texto: 'explicacion de la caracteristica'},{ id: 3, titulo: 'Caracteristica 3', texto: 'explicacion de la caracteristica'},{id: 4, titulo: 'Caracteristica 4', texto: 'explicacion de la caracteristica'}],
        descripcionBreve: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto, un libro de textos especimen. ",

    },

]

export default servicios;