import React from 'react';
import '../styles/redes.css';
import '../styles/links-redes.css';
import '../styles/responsive.css';
import imgIns from '../img/instagram2.svg';
import imgLink from '../img/linkedin3.svg';

function RedesColaboradores(props) {
    return (
        <div className='redes-colaboradores'>
            <a className='links links-linkedin' target="_blank" rel="noopener noreferrer" href={props.enlaceLinkedin}><img className='imagen-redes' src={imgLink} alt='LinkedIn Brainy fun' /></a>
            <a className='links links-instagram' target="_blank" rel="noopener noreferrer" href={props.enlaceInstagram}><img className='imagen-redes' src={imgIns} alt='Instagram Brainy fun' /></a>
        </div>)
}

export default RedesColaboradores;
