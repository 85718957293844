import React from "react";
import { Route, Switch } from "react-router-dom";
import NuevoMain from "./containers/NuevoMain.jsx";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import WhyUs from "./containers/Why-us.jsx";
import NotasViernes from "./containers/NotasViernes.jsx";
import PaginaServicios from "./containers/PaginaServicios.jsx";
import PaginaDocentes from "./containers/PaginaDocentes.jsx";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <NuevoMain />
      </Route>
      <Route exact path="/servicio/:id">
        <PaginaServicios />
      </Route>
      <Route path="/docente/:id">
        <PaginaDocentes />
      </Route>
      <Route exact path="/politica-de-privacidad">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/why-us">
        <WhyUs />
      </Route>
      <Route exact path="/notas-viernes">
        <NotasViernes />
      </Route>
    </Switch>
  );
}
